// eslint-disable-next-line no-redeclare,no-unused-vars
function getLocalFlag(key) {
  var value = localStorage.getItem(key);
  if (value) {
    if (isNumeric(value)) value = parseInt(value);
    return value;
  } else return null;
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function setLocalFlag(key, value) {
  if (value) localStorage.setItem(key, 1);
  else localStorage.setItem(key, 0);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function toggleLocalFlag(key) {
  var value;
  if (getLocalFlag(key)) value = 0;
  else value = 1;
  localStorage.setItem(key, value);
  return value;
}
